export default function setupTogglers () {  
  const clickHandler = function (ev) {
    ev.preventDefault()

    const eventId = $(this).attr('event_id')
    const parent = $(this).parent()
    $('#arrow_' + eventId).toggleClass('down')

    if ($('.event_' + eventId).hasClass('dropdown__slide')) {
      if ($('.event_' + eventId).hasClass('dropdown__slide--open')) {
        $('.event_' + eventId).removeClass('dropdown__slide--open')
        parent.removeClass('active')
        $('.event_' + eventId).addClass('dropdown__slide--close')
        $('.event_' + eventId).addClass('dropdown__slide--no-border')
      } else {
        $('.event_' + eventId).removeClass('dropdown__slide--close')
        $('.event_' + eventId).addClass('dropdown__slide--open')
        parent.addClass('active')
        $('.event_' + eventId).removeClass('dropdown__slide--no-border')
      }
    } else {
      $('.event_' + eventId).stop().slideToggle()
    }
  }

  // Check if a click event listener is already attached
  if (!$('.toggler').data('clickAttached')) {
    $('.toggler').on('click', clickHandler)
    // Mark that a click event listener is attached
    $('.toggler').data('clickAttached', true)
  }
}